import {I18nLocalSearch, I18nLocalWebFooter, I18nLocalWebHeader, I18nLocalHome, I18nLocalCommodity, I18nLocalCommodityNone,
    I18nLocalCommodityDetail, I18nLocalCommodityList, I18nLocalTargetBar, I18nLocalCompanyResume, I18nLocalPlatform, I18nLocalInformationList,
    I18nLocalBuyingGuide, I18nLocalPayWay, I18nLocalAttestation, I18nLocalTariff, I18nLocalNotification, I18nLocalServiceAgreement,
    I18nLocalPackaging, I18nLocalEntrust, I18LocalCompanyProfile, I18nLocalLogisticsTracking, I18nLocalFreightBase, I18nLocalDistributionMode,
    I18nLocalDistributionService, I18nLocalCustomizeLogistics, I18nLocalTaxRate,
}
    from './locale'
export * from 'zent/es/i18n/zh-CN';

export const WebHeader : I18nLocalWebHeader = {
    index: "闲蛋商城首页",
    title: "欢迎来到闲蛋商城",
    nav: [ "个人中心", "登录/注册","常见问题", "意见建议", "公司简历", "消费者告知书", "客户服务" ],
}

export const WebFooter : I18nLocalWebFooter = {
    specialty: [{
        icon:'正',
        title: '正品承诺',
        info: '正品保障 假一赔十'
    },{
        icon: '低',
        title: '低价保障',
        info: '缩减中间环节 确保低价'
    },{
        icon: '邮',
        title: '满99包邮',
        info: '部分特殊商品除外'
    }],
    nav: [
        // [{ name:'购物流程', path: '/info/buyingguide' },
        //     { name: '支付方式', path: '/info/payment' },
        //     { name: '通关税费', path: '/info/tariff' },
        //     { name:'常见问题', path: '/info/customsplicy' }
        // ],
        // [
        //     {name: '配送方式', path: '/info/distributionmode'},
        //     {name: '配送服务', path: '/info/distributionservice'},
        //     {name: '运费标准', path: '/info/freightbase'},
        //     {name: '物流跟踪', path: '/info/logisticstracking'}
        //     ],
        [
            {name:"海关政策",path:"/info/customs"},
            {name:"消费者告知书",path:"/info/note"},
            {name:"进口个人申报委托函",path:"/info/entrust"},
        ],[
            {name:"闲蛋商城服务协议",path:"/info/agreement"},
            {name:"商品包装与日期",path:"/info/packaging"},
            {name: "跨境电商综合税说明", path: "/info/taxrate"},
        ],[{name: '联系我们',path: '/contact-us'},
            {name:"公司简介",path:"/info/about"},
            {name: '招商合作', path: '/join-us'}
        ]
    ],
    desc: 'Copyright ©2019, HOPE U (CHENGDU) Co., Ltd. All Rights Reserved.',
    code: "闲蛋商城公众号",
    rules: [{
        name:'服务协议',
        path:"/info/agreement"
    },{
        name:'隐私条款',
        path: "https://mp.weixin.qq.com/s/Eo2n2Lbr28qGbW-vovprNA",
    },{
        name: '风险告知书',
        path: "/info/note"
    }],
    companyName: '厚普创优(成都)网络科技有限责任公司',
    beian: '蜀ICP备18037207号-1',
}

export const Home : I18nLocalHome = {
    tide: "国潮爆款",
    optimization: "闲蛋优选",
    more: "更多>>",
    discountProduct: "优惠产品",
    hotSell: "热卖产品",
    recommend: "推荐产品",
    activity:"秒杀活动",
    activeTitle: "价格实惠 品质优秀",
    end: "到底了",
    index: "首页",
    all:"全部",
}

export const Search :I18nLocalSearch ={
    placeHolder: '请输入商品信息',
    search: '搜索',
    cart: '购物车',
    index:"首页",
    all: "全部商品",
    searchResult: "搜索结果",
    result1:"共为您找到",
    result2:"处搜索结果",
    none: "不好意思未能为您找到",
}

export const Commodity :I18nLocalCommodity = {
    countries: '国家/地区',
    types:"类型",
    comprehensiveSort: "综合排序",
    sortLowest: "由低到高",
    sortHighest: "由高到低",
    price: "价格",
    sure: "确定",
    pre: "上一页",
    next: "下一页",
    priceWarn: "请输入大于0的正整数",
    index: "首页",
    all: "全部",
    result: "搜索结果",
    result1:"共为您找到",
    result2:"款商品信息",
    none: "未搜索到该产品",
    placeHolder: '请输入商品信息',
    search: '搜索',
    cart: '购物车',
    searchResult: "搜索结果",
    commodityNone: "该产品暂未上线",
    searchNone: "未搜索到该产品",
}

export const CommodityDetail :I18nLocalCommodityDetail = {
    detail: '商品详情',
    sell: '立即购买',
    add: '加入购物车',
    price: '售价',
    carriage: '运费',
    carriageInfo: '满99元包邮，未满加10元运费',
    count: '数量',
    spec: '规格',
    collection: '收藏',
    warnInt: '商品数量必须是大于1的正整数',
    warnMost: '商品数量超出库存',
}

export const CommodityList :I18nLocalCommodityList = {
    sellCount: "人购买",
    source:["现货","保税","直邮"],
}

export const CommodityNone :I18nLocalCommodityNone = {
    none: "未搜索到该产品"
}

export const TargetBar :I18nLocalTargetBar = {
    index: "首页",
    all: "全部",
    result: "搜索结果",
}

export const CompanyResume :I18nLocalCompanyResume = {
    aboutTitle: "关于我们",
    aboutDesc: "闲蛋（跨境）电商和现在主流电商平台最大的区别在于“我们不做平台，所有商品均为自营”。我们采用\"完全自营\"模式，这样可以最大程度上避免鱼龙混杂，更加严格的管控商品来源。闲蛋商城主要销售化妆品、日用品、母婴产品和保健品，我们在日本、韩国、美国、澳洲等地设立办事处，深入产品原产地，直接对接品牌方、工厂、大型商超和全球顶级供应商，从源头杜绝假货，保证商品质量。顾客在商城下单后，商品会从海关监管的中国保税区发出,全程接受海关监管，进行100%阳光清关,物流信息全程可以查看。保税仓发货不同于普通进口，可享受国家税务优惠政策，从而可以在价格上与专柜、超市等其它销售渠道形成价格优势。商城采用“假货重赔”机制，赢取客户对商城的信赖。",
    progress: "发展历程",
    progressInfo: [{
        title: "项目启动",
        info: "项目开始启动，准备各种证件及系统研发工作.",
    }, {
        title: "海关注册",
        info: "完成海关相关手续",
    }, {
        title: "工服接入",
        info: "完成对接成都跨境贸易电子商务公共服务平台",
    }, {
        title: "保税仓库",
        info: "与四川汇通天下物流有限公司建立合作关系",
    }, {
        title: "产品备货",
        info: "与日本供货商联系备货事宜",
    }, {
        title: "平台上线",
        info: "货物到达双流机场，完成清关，顺利进入保税仓",
    }, {
        title: "商城周年庆",
        info: "新老用户喜相迎，惊喜多多，礼品多多"
    }],
    timeList: [{
        active: "2018-10",
        time: "2018.10",
    }, {
        active: "2019-01",
        time: "2019.01",
    }, {
        active: "2019-02",
        time: "2019.02",
    }, {
        active: "2019-03",
        time: "2019.03",
    }, {

        active: "2019-04",
        time: "2019.04",
    }, {
        active: "2019-06",
        time: "2019.06",
    }, {
        active: "2020-06",
        time: "2020.06"
    }],
    contact: "联系我们",
    contactAddress: "四川省成都市天府新区成都片区保税物流中心（B型）",
}

export const Platform :I18nLocalPlatform = {
    name: "入驻申请",
    title: "请填写公司详细信息",
    photo: "请上传营业执照正面照片",
    person: "联系人",
    phone: "联系电话",
    email: "联系邮箱",
    scope: "请填写主营项目",
    sellTitle: "请填写公司销售",
    sellNumber: "SKU数目",
    sellTotal: "预计年销售额（单位：万元）",
    isECommerce: "是否属于跨境电商",
    yes: "是",
    no: "否",
    upload: "若是境外注册公司请上传营业执照",
    uploadText: "如果是境外注册公司，请上传境外营业执照照片或者扫描文件",
    submit: "提交申请",
    succeed: "审核提交成功",
    after: "我们将在5个工作日内回复您",
    please: "请输入"
}

export const InformationList :I18nLocalInformationList = {
    beginnerGuide:"新手指南",
    beginnerGuideItem:[
        {name:"购物流程",path:"/info/buyingGuide"},
        {name:"支付方式",path:"/info/payment"},
        {name:"实名认证",path:"/info/attestation"},
        {name:"通关税费",path:"/info/tariff"},
        {name:"商品税率表",path:"/"},
        {name:"优惠券说明",path:"/"},
        {name:"红包规则说明",path:"/"},
    ],
    questions:"常见问题",
    questionsItem:[
        {name:"海关政策",path:"/info/customs"},
        {name:"消费者告知书",path:"/info/note"},
        {name:"进口个人申报委托函",path:"/info/entrust"},
        {name:"闲蛋商城服务协议",path:"/info/agreement"},
        {name:"公司简介",path:"/info/about"},
        {name:"商品包装与日期",path:"/info/packaging"},
        {name: "跨境电商综合税说明", path: "/info/taxrate"}
    ],
    logistics:"物流配送",
    logisticsItem:[
        {name:"配送方式",path:"/info/distributionMode"},
        {name:"配送服务",path:"/info/distributionservice"},
        {name:"运费标准",path:"/info/freightbase"},
        {name:"物流跟踪",path:"/info/logisticstracking"},
        {name:"自选物流",path:"/info/customizelogistics"},
    ],
    aboutUs:"关于我们",
    aboutUsItem:[
        {name:"联系我们",path:"/contact-us"},
        {name:"招商合作",path:"/join-us"},
    ],
}

export const BuyingGuide :I18nLocalBuyingGuide = {
    title: "购物流程",
    loginTitle:"注册并登录网站",
    loginStep:[
        "1.新用户注册：点击首页顶部“注册”进入注册页面后输入邮箱地址或手机号、密码及验证码，按照提示完成注册。",
        "2.老用户登录：请在登录页面输入登录账号或手机号，密码进行登录。"
    ],
    addTitle:"加入购物车",
    addStep:[
        "1.挑选商品后，您可以“立即购买”或者“加入购物车”；",
        " 2.在购物车中，系统默认每件商品的订购数量为1件，如果您想购买多件商品，可修改购买数量；",
        "3.在购物车中，您可以将商品单个或批量删除"
    ],
    submitTitle:"提交订单",
    submitStep:[
        "1. 浏览要购买的商品，选择购买数量点击“立即购买”，直接结算。",
        "2. 或者在购物车中，选择好商品及数量后“去结算”。",
        "3. 填写购物人信息，收货信息，使用优惠券等。"
    ],
    payTitle:"支付货款",
    payStep:[
        "确认无误后点击“提交订单”，生成新订单并显示订单编号。跳转至支付选择页面选择支付方式。"
    ],
    lookTitle:"查看订单状态",
    lookStep:[
        "您可进入“个人中心”→“我的订单”查看订单详细信息。"
    ],
    statusTitle:"订单状态",
    statusStep:[
        "1. 等待付款 当您提交订单但未支付货款时，订单状态将显示为等待付款。",
        "2. 已发货 当您的订单已由库房发出，正由快递公司送货，“订单状态”将显示已发货",
        "3. 交易成功 当您签收商品之后，也就是物流得到签收完成后，“订单状态”即显示交易成功",
        "4. 等待发货 当您的订单已经完成配货，正在等待发货时，订单状态将显示“等待发货”。",
        "5. 订单关闭 当您的订单已被关闭，订单状态将显示“订单关闭”。",
        "6. 交易失败 当您支付后，商品未发货之前，如果您想取消订单，则后台客服可以将订单设为交易失败。"
    ]
}

export const PayWay :I18nLocalPayWay = {
    title: "支付方式",
    payWayTile: "微信支付",
    payWay: "如果您已经拥有微信账户，可选择微信进行付款。",
}

export const Attestation:I18nLocalAttestation = {
    title: "实名认证",
    whyTitle: "1.为什么要进行实名认证？",
    whyDesc: "根据海关总署公告2016年第26号（关于跨境电子商务零售进出口商品有关监管事宜的公告），电子商务企业应当对购买跨境电子商务零售进口商品的个人（订购人）身份信息进行核实，并向海关提供由国家主管部门认证的身份有效信息。无法提供或者无法核实订购人身份信息的，订购人与支付人应当为同一人。 部分商品海关要求需要进行收货人实名，具体以下单的提示为准。 考拉将根据政策对您订单里的商品进行订购人实名或收件人实名认证，并保证会对您的个人信息做加密处理，并依法或依您授权使用，绝不对外泄露。",
    howTitle: "2.如何进行实名认证？",
    howDesc: [
        "(1) 在闲蛋购物怎么实名认证？",
        "：在“个人中心” - “实名认证”页面进行实名认证：按照要求填写姓名与身份证号，并上传对应的身份证正反面照片，保存成功即完成认证啦！"
    ],
}

export const Tariff:I18nLocalTariff = {
    titleTariff: "通关税费",
    titleCustomsPolicy:"《海关政策》",
    question: "目前对于购买跨境电商平台上的商品，国家的相关政策是怎样的",
    info: [{
        title:"(1).购买限值规定: ",
        info:"根据跨境电子商务零售进口税收政策，个人单笔交易限值人民币5000元，个人年度交易限值人民币26000元。跨境电子商务零售进口商品按货物征收关税和进口环节增值税、消费税，完税价格为实际交易价格，包括商品零售价格、运费和保险费；订购人为纳税义务人。在海关注册登记的电子商务企业、电子商务交易平台企业或物流企业作为税款的代收代缴义务人，代为履行纳税义务。"
    },{
        title:"(2).“个人自用、合理数量”原则：",
        info:"根据2015年海关加贸司58号文件 《加贸司关于加强跨境电子商务网购保税进口监管工作的函》第四条，各海关要加强跨境电子商务信息化系统建设，按照“个人自用、合理数量”原则，对信息化管理系统进行参数设置，加强对跨境保税进口商品、电商企业以及消费者信息数据的分析监控；加强对网购保税进口消费者的身份认证。发现异常情事应及时移交稽查或缉私部门，严厉打击利用跨境电子商务网购保税进口渠道“化整为零”进行走私的违法犯罪行为。"
    },{
        title:"(3).国家海关关于“个人自用、合理数量”中的数量值，具体是怎么规定的？",
        info:"目前，海关部门并没有发布关于“个人自用、合理数量”中具体数值规定的通知，如果您的订单在报关过程中被海关部门判定涉嫌违反此规则，系统会自动转为人工审核，由国家海关部门工作人员进行审核，具体审核时效视海关部门实际工作情况而定，请您谅解。"
    },{
        title: "(4).如果我的订单转海关人工审核了，审核时效多久？审核结果会怎么样？",
        info:"如果您的订单在报关过程中被海关部门判定涉嫌违反此规则，系统会自动转为人工审核，具体审核时效视海关部门实际工作情况而定；审核结果可能是正常放行，可能需要您提供合理自用解释说明，也可能被驳回不予放行。闲蛋商城严格遵守海关部门规定，请您谅解。"
    }],
    tips:"具体税率请参见“跨境电商综合税说明”",
    focus: "欢迎关注闲蛋商城",
    slogan: "有钱，有闲，有生活",
}

export const Notification:I18nLocalNotification = {
    title:"《消费者告知书》",
    info:[
        "1.您选购的跨境商品仅限个人自用，不得进行再次销售。",
        "2.您在闲蛋商城上购买的跨境商品等同于境外购买，商品本身可能无中文标签，您可通过商品详情页查看相关商品标签中文翻译或联系客服。",
        " 3.您购买的跨境商品符合原产地有关质量、安全、卫生、环保、标识等标准或技术规范要求，可能与我国标准有所不同，由此可能产生的危害、损失或者其他风险，将由您自行承担。",
        "4. 您知晓商品的订购人（即支付人）将被记录为进口方，必须遵守中国的法律法规。",
        " 5. 您同意委托商家选定适合的物流商，并将您所购买的商品运输至您指定的中国大陆境内的收货地点",

    ]
}

export const ServiceAgreement :I18nLocalServiceAgreement = {
    title: "《闲蛋商城服务协议》",
    info:[
        "欢迎使用 “闲蛋商城” 提供的服务，为了保障您的权益，请在使用“闲蛋商城”前，详细阅读本协议的所有内容，特别是加粗部分。当您勾选“我已看过并同意《服务协议》”时，您的行为表示您同意并签署了本协议，并同意遵守本协议中的约定。该协议构成您与“闲蛋商城”达成的协议，具有法律效力。",
        "本协议内容包括协议正文、本协议下述条款明确援引的其他协议、闲蛋商城已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。一旦相关内容发生变动，闲蛋商城将会通过电子邮件或网站公告等方式提示您。如果您不同意对本协议内容所做的修改，则应立即停止使用本服务；如果您继续使用本服务的，则视为您同意对本协议内容所做的修改。",
    ],
    mallTitle: "一、闲蛋商城",
    mallInfo: "您登录闲蛋商城后，可选购闲蛋商城提供的商品或服务，您还可以参加闲蛋商城组织的活动，使用其它信息服务及技术服务。",
    normTitle: "二、闲蛋商城服务规范 ",
    normInfo: [
        "1、闲蛋商城保障商品和服务质量，并尽可能准确、详尽地描述每一件商品。然而闲蛋商城不能逐一审查所有商品或服务的相关内容是准确、可靠和没有错误的，如您发现任何错误，请及时与闲蛋商城联系。需要特别说明的是，由于用不同的网络浏览器或不同的计算机显示屏观看时网页显示的差异，闲蛋商城上展示的商品在图像和颜色方面可能跟真实的物品不尽一致。因此，所有显示的图片、视频和其他商品显示方法仅限于图示目的。 ",
        "2、除非另有证明，闲蛋商城储存在其服务器上的数据是您使用闲蛋商城服务的唯一有效证据。",
        "3、在法律法规允许的最大限度内，闲蛋商城保留自行决定是否提供服务、注销用户帐号、清除或编辑内容或取消订单的权利。"
    ],
    useTitle: "三、闲蛋商城使用规则",
    useInfo: [
        "1、您可浏览闲蛋商城中的商品或服务信息，如您希望选购并支付订单的，您需先登录或注册闲蛋商城帐号，并根据页面提示提交选购的商品信息和个人信息，包括但不限于数量、规格、手机、姓名、身份证照片、送货地址等信息。",
        " 您在注册时承诺遵守法律法规、社会主义制度、国家利益、公民合法权益、公共秩序、社会道德风尚和信息真实性等七条底线，不得在注册资料中出现违法和不良信息，且您保证其在注册和使用帐号时，不得有以下情形：",
        "（a）违反宪法或法律法规规定的；",
        "（b）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；",
        "（c）损害国家荣誉和利益的，损害公共利益的； ",
        "（d）煽动民族仇恨、民族歧视，破坏民族团结的；",
        "（e）破坏国家宗教政策，宣扬邪教和封建迷信的；",
        "（f）散布谣言，扰乱社会秩序，破坏社会稳定的；",
        "（g）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；",
        "（h）侮辱或者诽谤他人，侵害他人合法权益的；",
        "（i）含有法律、行政法规禁止的其他内容的。",
        "若您提供给闲蛋商城的帐号注册资料不准确，不真实，含有违法或不良信息的，闲蛋商城有权不予注册，并保留终止您使用闲蛋商城各项服务的权利。若您以虚假信息骗取帐号注册或帐号头像、个人简介等注册资料存在违法和不良信息的，闲蛋商城有权采取通知限期改正、暂停使用、注销登记等措施。对于冒用关联机构或社会名人注册帐号名称的，闲蛋商城有权注销该帐号，并向政府主管部门进行报告。",
        "根据相关法律、法规规定以及考虑到闲蛋商城服务的重要性，您同意： ",
        "（a）在注册闲蛋商城帐号时或闲蛋商城根据法律法规要求时提交个人有效身份信息进行实名认证；",
        "（b）提供及时、详尽及准确的帐户注册资料；",
        "（c）不断更新注册资料，符合及时、详尽准确的要求，对注册帐号时填写的身份证件信息不能更新。",
        "闲蛋商城将按照相关法律法规规定，对您提交的注册资料进行核查认证。",
        " 您确认：在使用闲蛋商城服务过程中，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织并对您提交信息的真实性承担一切责任。若您不具备前述主体资格，则您及您的监护人应承担因此而导致的一切后果，闲蛋商城有权向您的监护人追偿。",
        "您知悉并理解，如不同账号绑定或关联同一手机号、同一支付账户、同一身份证信息、同一设备号或相同收货信息，所述不同账号将被视为关联账号。",
        "2、您同意并保证：闲蛋商城有权记录您在选购商品或服务时，在线填写的所有信息并提供给商家、第三方服务提供者（包括但不限于物流公司、支付机构）或闲蛋商城的关联公司。您保证该等信息准确、合法，您承担因该等信息错误、非法等导致的后果。您授权闲蛋商城在本服务期间有权使用上述信息及将向有关第三方提供该信息。如您提供的信息过期、无效进而导致闲蛋商城无法与您取得联系的，因此导致您在使用闲蛋商城服务中产生任何损失或增加费用的，应由您完全独自承担。 ",
        "3、您理解并同意：闲蛋商城实行先付款后发货的方式，您及时、足额、合法的支付选购商品或服务所需的款项是闲蛋商城给您发货的前提。",
        "4、您支付交易款项过程中，将被邀请复查选购商品或服务的信息，包括单价、购买数量，付款方式，商品的运输方式和费用等。前述存储于闲蛋商城服务器的订单信息被认为是您的该次交易对应的发货、退货和争议事项的证据。您点击“结算/立即支付/付款/提交订单”意味着您认可订单表格中包含的所有信息都是正确和完整的。由于众所周知的互联网技术因素等客观原因存在，闲蛋商城显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解。如因系统故障或闲蛋商城过失导致显示信息明显不合理的（包括但不限于商品价格明显偏低），请勿进行下一步操作并立即通知闲蛋商城进行修改，如您明知显示信息明显不合理仍然提交订单的，将被视为恶意购物行为，闲蛋商城将有权按照本条第7款进行处理。",
        "5、您知悉并确认，您所选购的商品的所有权在您完成支付且闲蛋商城完成打包时由闲蛋商城转移给您。",
        "6、您所选购的商品或服务将被送至订单上注明的送货地址。该等物流服务由闲蛋商城合作物流服务提供商提供。无论何种原因该商品不能送达到送货地址，请您尽快跟页面公示的闲蛋商城客服取得联系并解决。在您自行选择物流服务提供商的情况下，商品运输过程中的全部风险与责任，将由您自行与该物流服务提供商协调处理。",
        "7、您理解并保证，您在使用闲蛋商城服务的过程中遵守诚实信用原则，不扰乱闲蛋商城的正常交易秩序，如果闲蛋商城根据您的登录帐号下的交易记录及其他相关信息，发现您有以下任一情形的：",
        "（a）您此前通过闲蛋商城购买的商品多数并非用于个人消费或使用用途的；",
        "（b）您此前存在多次（2次及以上）恶意购物行为的，即从事了相关购物行为，但积极主动终止购物目的或结果，或追求一般购物以外的目的或结果，对他人或闲蛋商城（可能）造成消极影响的行为。",
        "（c）闲蛋商城有合理理由怀疑您存在违反诚实信用原则的其他行为。 则，闲蛋商城有权自行拒绝您的购买需求，若您已下达订单的，闲蛋商城有权单方面取消订单不予发货而无需承担任何责任；同时，闲蛋商城有权视情况冻结您的登录帐号，使之无法通过闲蛋商城下达订单、购买商品。您确认并同意，前述管理措施将同样适用于您的关联账号。",
        " 8、为使您能够及时、全面了解闲蛋商城提供的各类商品、服务及活动，您了解并同意，闲蛋商城可以通过您在注册、使用闲蛋商城过程中提供的联系方式多次、长期向您发送各类商业性短信息而无需另行获得您的同意。",
        "9、您了解并同意，根据国家相关的监管法律法规，您委托闲蛋商城接入的第三方支付机构向其合作银行发送购买外汇的请求并将相关支付信息及支付账户实名认证信息（包括姓名及身份证号码等）发送给闲蛋商城。因此您还需同意该第三方支付机构需要您确认的相关协议，并承担由此所产生的法律后果。",
        "10、您知悉并同意，闲蛋商城将按照页面公示的《闲蛋商城隐私政策》的约定收集、使用并保护您的相关信息。",
        "11、您知悉并确认，您因使用闲蛋商城而发生的所有应纳税赋以及其它方面的费用均由您自行支付。您保证遵守《海关法》和国家相关法律法规，在闲蛋商城购买的商品均为您个人合理自用，您愿意接受海关、检验检疫机构及其他监管部门的监管，并承担相应法律责任。",
        "12、跨境电子商务零售进口商品的单次交易限值与年度交易限值按照国家相关政策执行，如因超过上述限值而产生的所有后果（包括但不限于清关失败等）及法律责任均由您自行承担，如因此给闲蛋商城造成任何损失的，闲蛋商城有权向您追偿。",
    ],
    otherTitle: "四、其他约定",
    otherInfo: [
        "1、您如对本协议项下闲蛋商城服务有任何疑问，请登录获取相关信息或联系客服。",
        "2、责任范围和限制",
        "A 对发生下列情形之一所造成的不便或损害，您同意闲蛋商城免责，但闲蛋商城将尽合理努力尽快恢复正常运作并止损：",
        "(a) 在闲蛋商城已尽必要管理的情况下，定期检查或施工，更新软硬件而造成的常规或紧急服务中断，或突发性的软硬件设备与电子通信设备故障；",
        "(b) 网络服务提供商线路故障或其他超出闲蛋商城合理控制范围的第三方行为造成服务器遭受损害，无法正常运作；",
        "(c) 在紧急情况之下依照法律的规定而采取的措施；",
        "(d) 因您提供的身份信息不完整或不准确而导致的海关通关失败，或因您自身原因导致所购买的商品被海关扣留；",
        "(e) 您与其它任何第三方的纠纷。",
        "B 无论何种原因闲蛋商城对您的购买行为的赔偿金额将不会超过您受到影响的当次购买行为已经实际支付的费用的总额。",
        "3、所有权及知识产权：",
        "1）闲蛋商城上所有内容，包括但不限于文字、软件、声音、图片、录像、图表、网站架构、网站画面的安排、网页设计、在广告中的全部内容、商品以及其它信息均由闲蛋商城或其他权利人依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。非经闲蛋商城或其他权利人书面同意，您不得擅自使用、修改、全部或部分复制、公开传播、改变、散布、发行或公开发表、转载、引用、链接、抓取或以其他方式使用本平台程序或内容。如有违反，您同意承担由此给闲蛋商城或其他权利人造成的一切损失。",
        "2）闲蛋商城不保证平台上由其他权利人提供的所有内容没有侵犯任何第三方的合法权益，如您认为前述内容侵犯您的合法权益，请及时与闲蛋商城客服联系，闲蛋商城将在法律规定范围内协助您解决问题。",
        "4、通知：所有发给您的通知都可通过电子邮件、常规的信件或在网站显著位置公告的方式进行传送。",
        "5、您了解并同意，闲蛋商城根据自身的判断，认为您的行为涉嫌违反本协议的条款，则闲蛋商城有权暂停或停止向您提供服务，且无须为此向您或任何第三方承担责任。",
        "6、本协议适用中华人民共和国的法律。当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关条款将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。",
        "7、如在使用闲蛋商城过程中，就自营商品产生的任何纠纷，应由您和相应销售方进行协商处理，就平台化服务产生的任何纠纷，应由您和成都闲蛋猫电子商务有限责任公司进行协商处理。若协商不成，应将上述纠纷提交中国国际经济贸易仲裁委员会由三名仲裁员根据其仲裁规则进行仲裁。",
        "8、本协议自发布之日起实施，并构成您和闲蛋商城之间的共识。",
        "9、如果您对本协议内容有任何疑问，请前往闲蛋商城（www.xiandanmall.com）联系客服。"
    ],
}

export const Packaging:I18nLocalPackaging = {
    title: "《商品包装与日期》",
    info: [
        "一.为什么有的国外包装很简单，连外盒、封口都没有？",
        "您好，国外的一些商品的包装确实比较简单，大部分国际一线品牌、奢侈品的包装也很简单，比如产自欧美、日韩的化妆品，大部分无外盒，不塑封，开口处也没有封口贴。",
        "二.我收到的商品日期喷码都模糊了，看不清？",
        "海外商品仓储物流周期相对较长，有些喷印的日期，在转关、清关等运输过程中因为摩擦碰撞很容易糊掉或被碰掉，是正常现象，请放心使用。",
        "三.国外商品包装上的保质期、生产日期怎么看？",
        "目前，国外的商品包装上一般不会单独列出保质期是多长的，只会在产品包装上标注生产日期或有效日期，也有只标注出厂批号没有标日期的情况，比如日本和欧洲的洗护品、化妆品，只有批号没有标日期（有些品牌可以根据批号读取日期，在此不做一一列举）。",
        "在此，列举常见国家一般情况下的日期读取方法，供您参考；非一般情况下的日期读取，您可联系官方客服沟通核实。",
        "韩国：",
        "默认没有韩文或字母提示的日期一般为生产日期（生产日期和有效日期同时标注时，日期较大的为有效日期），日期格式是“年/月/日”或“年/月”，也有的是沿用英语系国家的标注方式。",
        "韩文：제조（制造/生产）",
        "韩文：까지（截至）；기한（期限）",
        "日本：",
        "日本食品标注的是有效日期，日期格式是“年/月/日”，或“年/月”。",
        "“赏味期限”是指可以美味地享用食品的期限，即便过了该期限，食品仍可以食用。",
        "“消费期限”是指一旦过了该期限，就可能发生腐烂变质，最好不要食用。",
        "日本洗护、护肤品和彩妆，没有标注日期。",
        "根据日本《药事法》的规定，对于那些\"在通常的保管条件下3年以內会发生质变\"的化妆品，才要求在商品上标明有效日期。日本一般未开封的基础护肤品保质期为3年，彩妆是5年，所以没有标日期。开封后请尽量在12个月内用完。",
        "越南：",
        "日期格式是“日/月/年”，或“月/年”。",
        "NSX、MFG，代表英文production/date （生产日期）。",
        "HSD、BBE，代表英文best before（有效日期）。",
        "英语系（包括欧美、澳洲、新西兰等）国家：",
        "默认没有字母提示的日期为有效日期（生产日期和有效日期同时标注时，日期较大的为有效日期）。",
        "欧洲食品标注的是有效日期，日期格式是“日/月/年”，或“月/年”，或“第几天/年”。",
        "欧洲的洗护品、化妆品，一般不标注日期。",
        "根据欧洲护肤品统一条例规定：凡可使用30个月以上的商品，可不写保质期。所以欧洲很多包装上只有出厂批号没有标注日期。生产自欧洲的护肤品，开口处均没有封口贴。保质期默认为开盖后12个月内使用，包装上一般有此类图标",
        "MFD、MFG、MAN（Manufacturing Date的简写）：意思都是制造/生产日期。",
        "例如，",
        "MFG 300515 08:02，表示生产日期是2015年05月30日，08:02；",
        "MFG 229-2014，表示生产日期是2014年第229天。",
        "EXP（Expiry date的简写)：是指有效日期。",
    ]
}

export const Entrust:I18nLocalEntrust = {
    title: "《进口个人委托申报委托函》",
    info: "本人承诺所购买商品系个人合理自用，现委托商家代理申报、代缴税款等通关事宜，本人保证遵守《海关法》和国家相关法律法规，保证所提供的身份信息和收货信息真实完整，无侵犯他人权益的行为，以上委托关系系如实填写，本人愿意接受海关、检验检疫机构及其他监管部门的监管，并承担相应法律责任。",
}

export const CompanyProfile:I18LocalCompanyProfile = {
    title: "《公司简介》",
    aboutHope: [{
        info:"关于厚普创优",
        type:1,
    },{
        info:"厚普创优(成都)网络科技有限公司位于四川成都，是在高新技术应用领域中专业从事应用系统开发、信息安全服务、电子商务的高新技术企业。",
        type:1,
    },{
        info:"厚普创优倡导“专业、务实、高效、创新”的企业精神。",
        type:1,
    },{
        info:"我们可以提供：",
        type: 1
    },{
        info:"——安全、完整的系统策划和设计： ",
        type: 2,
    },{
        info:"公司在产品研发方面的高投入，众多项目的实际应用，让我们具备了相应的的创造力和丰富的经验，这将成为您解决疑问和难题的良好保证。可为您提供多方面完善的策划：如项目的规划立项、总体方案设计、方案评估论证。",
        type:1,
    },{
        info: "——先进、专业的技术支持：",
        type: 2,
    },{
        info: "公司众多一流人才的深层磨合，对最新技术执拗的探讨精神，使我们能够保证为你提供最专业的应用，最专业的服务。",
        type:1,
    },{
        info: "——完善、快速的售后服务：",
        type: 2,
    },{
        info: "以最快的速度、最有效的方法、最先进的技术保障系统的效果发挥到极至，解除您的后顾之忧。——先进、专业的技术支持：",
        type:1,
    }],
    hopeTitle:"关于闲蛋商城",
    hopeInfo: "闲蛋（跨境）商城是目前公司的主项目，和现在主流电商平台最大的区别在于“我们不做平台，所有商品均为自营”，最大程度上避免鱼龙混杂，更加严格的管控商品来源。闲蛋商城主要销售化妆品、日用品、母婴产品和保健品，我们在日本、韩国、美国、澳洲等地设立办事处，深入产品原产地，直接对接品牌方、工厂、大型商超和全球顶级供应商，从源头杜绝假货，保证商品质量。顾客在商城下单后，商品会从海关监管的中国保税区发出,全程接受海关监管，进行100%阳光清关,物流信息全程可以查看。保税仓发货不同于普通进口，可享受国家税务优惠政策，从而可以在价格上与专柜、超市等其它销售渠道形成价格优势。",
    more: [
        "了解更多",
        "官网：https://www.xiandanmall.com/",
        "邮箱：contact@xiandanmall.com",
        "电话：028-67649839",
        "地址：四川省成都市天府新区成都片区保税物流中心综合办公楼407"
    ],
    imgList: "以下是公司营业执照、对外贸易经营者备案登记表、检疫备案表等基本信息扫描件。",
}

export const LogisticsTracking:I18nLocalLogisticsTracking = {
    title:"物流跟踪",
    info:[
        "您可以登录您的考拉海购账户，在我的订单—订单详情页面中查看您订单的实时配送情况。",
        "部分快递公司物流信息系统显示会略有延迟，如订单已显示为发货状态，但无物流信息显示，请您稍等约24小时左右，即可查询到。或者您可以直接在页面点击物流公司官网进行实时订单物流状态查询。 ",
        "请您签收货品时，尽量在快递人员在场时当面检查商品（如果快递人员坚持先前签收再验货，可以在签收之后立刻检查）。 ",
        "请注意： ",
        "务必检查封箱胶带是否为考拉海购专用胶带，胶带是否有被拆开重新粘贴过的痕迹。 ",
        "务必检查包装箱是否为考拉海购专用红色包装箱。 ",
        "快递包装是否有破损、渗漏、缺少等现象。",
        "若出现以上情况，建议您尽快与我们取得联系，并拍照存档，把照片发给我们的客服人员，以便我们对物流快递公司进行追责。 ",
        "退货运费说明参见 退款说明",
    ]
}

export const FreightBase:I18nLocalFreightBase = {
    title: "运费标准",
    info: [
        "1.国际段物流费用由考拉海购承担",
        "2.国内段运费标准如下：",
        "考拉海购自营商品满88元包邮，不足88元需承担10元运费。以自营商品活动后的总金额为准，不含税费，使用虚拟资产(优惠券、红包、考拉豆等)不影响包邮的计算；部分超大超重的特殊商品及入驻商家商品单独计算运费。",
        "考拉自营商品运费标准分三类：免运费的商品、因超大超重单独收取运费的商品、满88包邮的商品。",
        "您可在商品详情页-运费项查看商品的运费标准。具体可查看下方三张详情页的示例。",
        "若一笔订单中包含以上三种商品，免运费商品和超大超重商品金额计入88元包邮的金额，若总金额不足88元，则除10元运费外，超大超重商品需额外收取运费，若满足88元，则只需收取超大超重商品的运费。",
        "运费售后：若您承担运费的商品发生退货，运费会按照商品价格比例分摊后退还，具体说明请浏览邮费退还说明。",
    ],
    announcements: [
        "实际产生运费，请以提交订单页显示为准",
        "随着考拉海购业务的发展，运费标准可能会进行相应调整，请留意页面说明",
    ]
}

export const DistributionMode:I18nLocalDistributionMode = {
    title: "配送方式",
    bonded: "保税仓商品配送",
    info: [
        "如果您的订单从保税仓发出，自物流公司接收包裹至包裹派送到您的手中，不同城市的时限不同：",
        "长三角地区（江浙沪）重点城市最快1-2天送达",
        "部分西部偏远地区、郊县（如喀什、海拉尔等）预计4-6天到达",
        "其他地区2-3天不等",
        "体可至商品详情页查看预计送达时间 ",
        "如果您的订单从海外直邮回国，自仓库收到您的订单至订单派送到您手中，预计7-15个工作日即可到货。",
    ],
    announcements: [
        "注意事项：",
        "暂时不支持港澳台地区的派送",
        "如遇双十一等大型活动期间、订单需要实名认证等特殊情况，或者遇台风、暴雨、大雾等不可抗力的原因下，订单到达时间将根据具体到达情况而定。",
        "考拉海购提供的订单物流跟踪功能所查询的信息均来源于合作快递公司官网，部分官网信息由于同步不及时，有可能出现更新延迟现象，为您带来的不便敬请谅解。",
    ]
}

export const DistributionService:I18nLocalDistributionService = {
    title: "配送服务",
    serviceName: "一、什么是次日达？",
    serviceMean: [
        "次日达是闲蛋猫新推出的一项配送服务，旨在优化商品配送时效，提升客户体验，将全球好货尽快送达用户手中。",
        "指定商品、指定收货地址的现货订单，16点前下单并支付完成，商品将最快于次日送达。",
    ],
    areaTitle:"二、次日达服务覆盖区域",
    serviceArea: [{
        title:"1、次日达配送城市（一）：",
        content:[
            "浙江省",
            "杭州市（滨江区、拱墅区、江干区、上城区、西湖区、下城区、萧山区、余杭区）",
            "宁波市（北仑区、慈溪市、海曙区、江北区、江东区、余姚市、镇海区、鄞州区）",
            "嘉兴市（海宁市、嘉善县、南湖区、桐乡市、秀洲区）",
            "湖州市（南浔区、吴兴区）",
            "绍兴市（柯桥区、越城区、绍兴市区）",
            "金华市（婺城区、金东区、义乌市、永康市、东阳市）",
            "上海市（宝山区、奉贤区、虹口区、黄浦区、嘉定区、金山区、静安区、闵行区、浦东新区、普陀区、青浦区、松江区、徐汇区、杨浦区、闸北区、长宁区）",
            "备注：购买杭州保税1号仓、杭州保税3号仓、杭州保税5号仓的商品，于当日16:00前完成支付，即可支持以上城市区域的次日达配送。具体以商品详情页的配送服务提示为准。",
        ]
    }, {
        title:"2、次日达配送城市（二）：",
        content:[
            "河南省",
            "郑州市（郑东新区、高新区、惠济区、上街区、金水区、管城回族区、二七区、中原区）",
            "洛阳市（洛南新区、洛龙区、吉利区、涧西区、瀍河回族区、西工区、老城区）",
            "新乡市（牧野区、凤泉区、卫滨区、红旗区）",
            "许昌市（魏都区）",
            "漯河市（源汇区、郾城区、召陵区）",
            "焦作市（焦作市、解放区、中站区、马村区、山阳区）",
            "周口市（川汇区）",
            "鹤壁市（淇滨区、山城区）",
            "商丘市（睢阳区、梁园区）",
            "备注：购买郑州保税仓的商品，于当日16:00前完成支付，即可支持以上城市区域次日达配送。具体以商品详情页的配送服务提示为准。",
        ]
    }, {
        title: "3、次日达配送城市（三）：",
        content: [
            "广东省",
            "广州市（萝岗区、南沙区、花都区、番禺区、黄埔区、白云区、天河区、海珠区、越秀区、荔湾区、从化市、增城市）",
            "深圳市（盐田区、龙岗区、宝安区、南山区、福田区、罗湖区、大鹏新区、坪山新区、光明新区、龙华新区）",
            "珠海市（金湾区、斗门区、香洲区）",
            "佛山市（高明区、三水区、顺德区、南海区、禅城区）",
            "东莞市（万江区、南城区、莞城区、东城区、横沥镇、清溪镇、凤岗镇、塘厦镇、大朗镇、樟木头镇、厚街镇、常平镇、大岭山镇、寮步镇、长安镇、松山湖、谢岗镇、茶山镇、望牛墩镇、桥头镇、麻涌镇、洪梅镇、石碣镇、企石镇、石排镇、石龙镇、高步镇、沙田镇、道滘镇、黄江镇、东坑镇、中堂镇、虎门镇）",
            "中山市（火炬开发区、五桂山区、南区、西区、东区、石岐区、坦洲镇、神湾镇、板芙镇、三乡镇、沙溪镇、大涌镇、港口镇、南朗镇、民众镇、三角镇、横栏镇、古镇镇、东升镇、小榄镇、阜沙镇、东凤镇、南头镇、黄圃镇）",
            "备注：购买深圳保税仓的商品，于当日16:00前完成支付，即可支持以上城市区域次日达配送。具体以商品详情页的配送服务提示为准。",
        ]
    }, {
        title: "4、次日达配送城市（四）：",
        content: [
            "江苏省",
            "南京市（玄武区、秦淮区、鼓楼区、建邺区、雨花台区、栖霞区、浦口区、六合区、江宁区、溧水区、高淳区）",
            "无锡市（梁溪区、滨湖区、惠山区、锡山区、新吴区、江阴市、宜兴市）",
            "苏州市（姑苏区、相城区、吴中区、虎丘区、吴江区、常熟市、昆山市、张家港市、太仓市）",
            "常州市（天宁区、钟楼区、新北区、武进区、金坛区）",
            "无锡市（梁溪区、滨湖区、惠山区、锡山区、新吴区）",
            "镇江市（京口区、润州区、丹徒区）",
            "备注：购买杭州保税1号仓、杭州保税3号仓、杭州保税5号仓的商品，于当日16:00前完成支付，即可支持以上城市区域的次日达配送。具体以商品详情页的配送服务提示为准。",]
    }, {
        title: "5、次日达配送城市（五）：",
        content: [
            "重庆市",
            "重庆市（江北区、渝北区、北碚区、渝中区、九龙坡区、沙坪坝区、大渡口区、南岸区、巴南区）",
            "四川省",
            "成都市（武侯区、锦江区、青羊区、金牛区、成华区、龙泉驿区、温江区、新都区）",
            "备注：购买重庆保税仓的商品，于当日16:00前完成支付，即可支持以上城市区域的次日达配送。具体以商品详情页的配送服务提示为准。",
        ]
    }, {
        title: "6、次日达配送城市（六）：",
        content: [
            "北京市（所有区）",
            "天津市（所有区）",
            "廊坊市（安次区、广阳区）",
            "备注：购买天津保税仓、北京大贸仓的商品，于当日16:00前完成支付，即可支持以上城市区域的次日达配送。具体以商品详情页的配送服务提示为准。",
            "随着实际业务的变化，次日达服务所覆盖的区域会有所调整，考拉海购会相应不定期进行修订。",
        ]
    }],
    other: "三、其他说明",
    otherInfo: "支持次日达物流配送的具体订单，可能会因法定节假日、重大促销活动（如双十一、双十二等）、交通管制、自然灾害或其他不可抗拒因素等原因，导致实际到货时间有所延迟。考拉海购将依据消费者诉求与相关规则进行相应处理。",
}

export const CustomizeLogistics:I18nLocalCustomizeLogistics = {
    title: "自选物流",
    foreword: [
        "欢迎使用考拉海购平台的配送服务，为了保障您的权益，请在使用该服务前，详细阅读本协议的所有内容，特别是加粗部分。当您在提交订单时选择了配送服务时，您的行为表示您同意并签署了本协议，并同意遵守本协议中的约定。该协议构成您与考拉海购达成的协议，具有法律效力。 ",
        "本协议是《考拉海购服务协议》的补充协议，是其不可分割的组成部分，与其构成统一整体。本协议与上述内容存在冲突的，以本协议为准。 ",
        "本协议内容包括协议正文、考拉海购已经发布的或将来可能发布的与配送相关的各类规则。所有规则为本协议不可分割的组成部分，与协议正文具有同等法律效力。一旦相关内容发生变动，考拉海购将会通过电子邮件或网站公告等方式提示您。如果您不同意对本协议内容所做的修改，则应立即停止使用本服务；如果您继续使用本服务的，则视为您同意对本协议内容所做的修改。 ",
    ],
    info: [{
        title: "一、定义 ",
        rule: [
            "1、配送服务：指考拉海购代商家统一委托物流服务商向购买商家销售的商品的个人注册用户提供的物流配送服务，包括默认配送服务和可选配送服务。 ",
            "2、默认配送服务：指用户在考拉海购上下单购物时，商家通过默认的物流服务商向用户配送商品的服务，默认配送服务不支持用户选择物流服务商或个性化送达时间。 ",
            "3、可选配送服务：指可供用户在下单时自主选择物流服务商或个性化送达时间的配送服务。在此服务模式下，商家将通过用户选择的物流服务商进行商品配送或根据用户选择的送达时间将商品配送至用户指定地点。 ",
        ]
    }, {
        title: "二、配送服务规则 ",
        rule: [
            "1、您在考拉海购上下单时，可以就商品的配送选择默认配送服务或者可选配送服务。就本协议项下的配送服务，您需要支付一定的运费，但在您选择默认配送服务且满足特定条件的情况下，商家将给予免运费的优惠。具体运费标准和金额及免运费优惠条件以考拉海购页面公布的规则为准。 ",
            "2、如您在购买跨境商品（包括从保税仓及海外发货的商品）时选择了可选配送服务，根据相关法律法规规定，您需要根据您支付的运费支付相应的税费，具体税费金额及计算方式以商品页面显示为准。 ",
            "3、您知悉并理解，由于商品发货地、物流服务商的布局等方面原因，部分商品、订单及部分地区的用户将无法享受可选配送服务，具体商品及地区是否享受可选配送服务以商品订单页面是否显示为准。 ",
            "4、您知悉并确认，您选择可选配送服务并支付相应的运费后，如因您自身原因（包括但不限于实名认证信息不匹配导致清关迟延或支付完成后修改收货信息）导致订单商品未能按照您选择的送达时间送达，商家及考拉海购将不承担任何责任，且已收取的运费及税费将不予退还；但如因您支付超时导致订单商品无法按照您选择的送达时间送达或者由于商家原因或物流服务商原因导致订单商品未能按照您选择的送达时间送达，考拉海购将代商家在扣除默认配送运费及相应的税费后退还余下部分运费及运费税费，如您使用运费优惠券支付全部或者部分运费及运费税费时，退还规则亦同。如考拉海购页面公布的规则与本条款所确定的规则不一致，以考拉海购页面公布的规则为准。 ",
            "5、您知悉并同意，您在考拉海购下单时填写的配送相关信息将被提供给商家及相关的物流服务商。您保证该等信息准确无误，如您提供的信息过期、无效进而导致考拉海购、商家或物流服务商无法与您或您指定的收件人取得联系的，您将自行承担因此产生的所有损失及增加费用。 ",
            "6、您知悉并同意，考拉海购有权通过邮件、短信或电话等形式，向您发送配送服务相关信息。 ",
            "7、除法律法规另有规定外，考拉海购有权根据您的下单和支付时间决定是否接受您的可选配送服务申请、有权自行调整配送服务的内容及收费标准。 ",
        ]
    }, {
        title: "三、其他约定 ",
        rule: [
            "1、您如对本协议项下的配送服务有任何疑问，请登录（www.kaola.com）获取相关信息或拨打客服电话（95163111）。 ",
            "2、通知：所有发给您的通知都可通过电子邮件、常规的信件或在网站显著位置公告的方式进行传送。 ",
            "3、本协议适用中华人民共和国的法律。 当本协议的任何内容与中华人民共和国法律相抵触时，应当以法律规定为准，同时相关条款将按法律规定进行修改或重新解释，而本协议其他部分的法律效力不变。 ",
            "4、如使用配送服务过程中出现纠纷，您与商家及考拉海购应友好协商解决，若协商不成，应将纠纷提交中国国际经济贸易仲裁委员会根据其仲裁规则进行仲裁。",
        ]
    }]
}

export const TaxRate:I18nLocalTaxRate = {
    title: "《跨境电商综合税说明》",
    info: [
        "根据跨境电子商务零售进口税收政策，个人单笔交易限值人民币5000元，个人年度交易限值人民币26000元。在限值以内进口的跨境电子商务零售进口商品，关税税率暂设为0%；进口环节增值税、消费税按法定应纳税额的70%征收。计算规则如下：",
        "税费=购买单价 x 件数 x 跨境电商综合税率",
        "跨境电商综合税率 =[（消费税率 + 增值税率）/（1-消费税率）]×70%",
        "注：优惠券仅可抵扣商品金额，运费需缴纳税费。税费为海囤全球代征代缴，如因个人原因退货，税费将不予返还；如因商品本身问题产生退货，税费将以原来支付形式为您返还。",
    ],
    tableTitle: [{
        title: "类目",
        name:"type",
        colspan:2,
    }, {
        name:"addValueTax",
        title: "增值税率(%)",
        defaultText: 13,
    }, {
        title:"消费税率(%)",
        name: "consumptionTax",
        defaultText: "0"
    }, {
        title:"跨境电商综合税(%)",
        name: "crossBorderTax",
        defaultText: 9.1
    }],
    tableInfo: [{
        type: "婴儿奶粉",
        name: "婴儿奶粉",
        colspan: 1,
    }, {
        type:"成人奶粉",
        name: "成人奶粉",
        colspan: 1,
    }, {
        type:"纸尿裤",
        name: "纸尿裤",
        colspan: 1,
    }, {
        type: "保健品",
        name: "保健品",
        colspan: 1,
    }, {
        type: "食品",
        name: "麦片、薯片、膨化食品",
        colspan: 25,
    }, {
        type: "食品",
        name: "巧克力",
        colspan: 0
    }, {
        type: "食品",
        name: "水果干",
        colspan: 0
    }, {
        type: "食品",
        colspan: 0,
        name: "坚果类"
    }, {
        type: "食品",
        colspan: 0,
        name: "咖啡"
    }, {
        type: "食品",
        colspan: 0,
        name: "矿泉水"
    }, {
        type: "食品",
        colspan: 0,
        name: "汽水"
    }, {
        type: "食品",
        colspan: 0,
        name: "液态奶"
    }, {
        type: "食品",
        colspan: 0,
        name: "果汁饮料"
    }, {
        type: "食品",
        colspan: 0,
        name: "口香糖"
    }, {
        type: "食品",
        colspan: 0,
        name: "软糖"
    }, {
        type: "食品",
        colspan: 0,
        name:"黑糖"
    }, {
        type: "食品",
        colspan: 0,
        name:"蔬菜泥"
    }, {
        type: "食品",
        colspan: 0,
        name: "果泥"
    }, {
        type: "食品",
        colspan: 0,
        name: "调味品"
    }, {
        type: "食品",
        colspan: 0,
        name: "面食"
    }, {
        type: "食品",
        colspan: 0,
        name: "饼干"
    }, {
        type: "食品",
        colspan: 0,
        addValueTax: 9,
        name:"菜籽油",
        crossBorderTax: 6.3
    }, {
        type: "食品",
        colspan: 0,
        addValueTax: 9,
        name: "橄榄油",
        crossBorderTax:6.3
    }, {
        type: "食品",
        colspan: 0,
        addValueTax: 9,
        name:"亚麻籽油",
        crossBorderTax:6.3
    }, {
        type: "食品",
        colspan: 0,
        addValueTax: 9,
        name: "天然蜂蜜",
        crossBorderTax:6.3
    }, {
        type: "食品",
        colspan: 0,
        name: "葡萄酒",
        crossBorderTax:17.9,
        consumptionTax:10,
    }, {
        type: "食品",
        colspan: 0,
        name: "葡萄汽酒",
        consumptionTax:10,
        crossBorderTax:17.9
    }, {
        type: "食品",
        colspan: 0,
        name: "啤酒",
    }, {
        type: "食品",
        colspan: 0,
        name: "即食燕窝",
    }, {
        type: "化妆品",
        colspan: 10,
        name: "面膜（片装）<15元/片",
    }, {
        type: "化妆品",
        colspan: 0,
        name: "面膜（片装）>=15元/片",
        consumptionTax:15,
        crossBorderTax:23.1
    }, {
        type: "化妆品",
        colspan: 0,
        name: "护肤品（非片装面膜）<10元/g或<10元/ml",
    }, {
        type: "化妆品",
        colspan: 0,
        name: "护肤品（非片装面膜）>=10元/g或>=10元/ml",
        consumptionTax:15,
        crossBorderTax:23.1
    }, {
        type: "化妆品",
        colspan: 0,
        name: "化妆品/彩妆（片装）<15元/片",
    }, {
        type: "化妆品",
        colspan: 0,
        name: "化妆品/彩妆（片装）>=15元/片",
        consumptionTax:15,
        crossBorderTax:23.1
    }, {
        type: "化妆品",
        colspan: 0,
        name: "化妆品/彩妆（非片装）<10元/g或<10元/ml",
    }, {
        type: "化妆品",
        colspan: 0,
        name: "化妆品/彩妆（非片装）>=10元/g或>=10元/ml",
        consumptionTax:15,
        crossBorderTax:23.1
    }, {
        type: "化妆品",
        colspan: 0,
        name: "香水<10元/ml",
    }, {
        type: "化妆品",
        colspan: 0,
        name: "香水>=10元/ml",
        consumptionTax:15,
        crossBorderTax:23.1
    }, {
        type: "个护",
        colspan: 3,
        name: "洗发水",
    }, {
        type: "个护",
        colspan: 0,
        name: "沐浴露",
    }, {
        type: "个护",
        colspan: 0,
        name: "牙膏",
    }, {
        type: "3C",
        colspan: 14,
        name: "手机、平板电脑"
    }, {
        type: "3C",
        colspan: 0,
        name: "耳机、音箱"
    }, {
        type: "3C",
        colspan: 0,
        name: "键盘、鼠标"
    }, {
        type: "3C",
        colspan: 0,
        name: "滤水壶"
    }, {
        type: "3C",
        colspan: 0,
        name: "美容仪"
    }, {
        type: "3C",
        colspan: 0,
        name: "吹风机"
    }, {
        type: "3C",
        colspan: 0,
        name: "电饭锅"
    }, {
        type: "3C",
        colspan: 0,
        name: "咖啡机"
    }, {
        type: "3C",
        colspan: 0,
        name: "面包机"
    }, {
        type: "3C",
        colspan: 0,
        name: "榨汁机"
    }, {
        type: "3C",
        colspan: 0,
        name: "电动剃须刀类"
    }, {
        type: "3C",
        colspan: 0,
        name: "电动牙刷"
    }, {
        type: "3C",
        colspan: 0,
        name: "相机及镜头"
    }, {
        type: "3C",
        colspan: 0,
        name: "视频游戏及配件"
    }, {
        type: "服装、轻奢",
        colspan: 12,
        name: "女装、男装、内衣"
    }, {
        type: "服装、轻奢",
        colspan: 0,
        name: "包、钱包、箱包"
    }, {
        type: "服装、轻奢",
        colspan: 0,
        name: "贱金属仿首饰（项链、耳环等）"
    }, {
        type: "服装、轻奢",
        colspan: 0,
        name: "玻璃制仿首饰（项链、耳环等）"
    }, {
        type: "服装、轻奢",
        colspan: 0,
        name: "完税价格小于1万元手表"
    }, {
        type: "服装、轻奢",
        colspan: 0,
        name: "完税价格大于等于1万元手表",
        consumptionTax:20,
        crossBorderTax:28.9
    }, {
        type: "服装、轻奢",
        colspan: 0,
        name: "包贵金属制首饰",
        consumptionTax:10,
        crossBorderTax:17.9
    }, {
        type: "服装、轻奢",
        colspan: 0,
        name: "宝石或半宝石制品、天然或养殖珍珠制品",
        consumptionTax:10,
        crossBorderTax:17.9
    }, {
        type: "服装、轻奢",
        colspan: 0,
        name: "鞋"
    }, {
        type: "服装、轻奢",
        colspan: 0,
        name: "皮带"
    }, {
        type: "服装、轻奢",
        colspan: 0,
        name: "围巾"
    }, {
        type: "服装、轻奢",
        colspan: 0,
        name: "手机壳"
    }, {
        type: "其他",
        colspan: 15,
        name: "玩具"
    }, {
        type: "其他",
        colspan: 0,
        name: "厨具"
    }, {
        type: "其他",
        colspan: 0,
        name: "安全座椅"
    }, {
        type: "其他",
        colspan: 0,
        name: "避孕套",
        addValueTax: "0",
        crossBorderTax: "0",
    }, {
        type: "其他",
        colspan: 0,
        name: "蒸汽眼罩"
    }, {
        type: "其他",
        colspan: 0,
        name: "防蚊贴"
    }, {
        type: "其他",
        colspan: 0,
        name: "退热贴"
    }, {
        type: "其他",
        colspan: 0,
        name: "宠物食品（猫粮、狗粮）",
        addValueTax: 9,
        crossBorderTax:6.3
    }, {
        type: "其他",
        colspan: 0,
        name: "节日用品"
    }, {
        type: "其他",
        colspan: 0,
        name: "篮球、足球、排球"
    }, {
        type: "其他",
        colspan: 0,
        name: "网球拍、羽毛球拍"
    }, {
        type: "其他",
        colspan: 0,
        name: "溜冰鞋、旱冰鞋"
    }, {
        type: "其他",
        colspan: 0,
        name: "健身及康复器械"
    }, {
        type: "其他",
        colspan: 0,
        name: "渔具（钓鱼竿、钓鱼钩、钓线轮）"
    }, {
        type: "其他",
        colspan: 0,
        name: "高尔夫球",
        crossBorderTax:17.9,
        consumptionTax:10
    },],
}

